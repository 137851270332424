import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import ru from 'react-intl/locale-data/ru';
import pt from 'react-intl/locale-data/pt';
import uk from 'react-intl/locale-data/uk';

import enMessages from '../../../i18n-messages/dist/en-uk.json';
import { loadState, saveState } from '../statePersist/localStore';
import { LANG_KEY } from '../../constants/constants';

const DEFAULT_LANGUAGE = 'en-UK';

addLocaleData([...ru, ...pt, ...uk]);

export const LanguageContext = React.createContext<
  [string, Dispatch<SetStateAction<string>>]
>(['', () => {}]);
LanguageContext.displayName = 'LanguageContext';

function ConfiguredIntlProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [language, setLanguage] = useState<string>(
    loadState(LANG_KEY) || DEFAULT_LANGUAGE,
  );
  const [messages, setMessages] = useState(enMessages);

  useEffect(() => {
    async function fetchMessages() {
      try {
        const nextMessages = await import(
          `../../../i18n-messages/dist/${language.toLowerCase()}.json`
        );
        setMessages(nextMessages.default);
      } catch (error) {
        console.error('Dynamic lang messges load failure: ', error);
      }
      return null;
    }

    saveState(LANG_KEY, language);
    if (language !== DEFAULT_LANGUAGE) {
      fetchMessages();
    } else {
      setMessages(enMessages);
    }
  }, [language]);

  return (
    <IntlProvider locale={language} messages={{ ...enMessages, ...messages }}>
      <LanguageContext.Provider value={[language, setLanguage]}>
        {children}
      </LanguageContext.Provider>
    </IntlProvider>
  );
}

export default ConfiguredIntlProvider;
