import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { SentryRoute } from 'services/sentry';
import Logout from './pages/Logout';
import Login from './pages/Login';
import ForgottenPassword from './pages/ForgottenPassword';

function AppRouter(): React.ReactElement {
  return (
    <Router>
      <Switch>
        <SentryRoute path="/login">
          <Login />
        </SentryRoute>
        <SentryRoute path="/forgotten-password">
          <ForgottenPassword />
        </SentryRoute>
        <SentryRoute exact path="/logout">
          <Logout />
        </SentryRoute>
        <Route exact path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;
