import React from 'react';
import { createUseStyles } from 'react-jss';
import { Li, SquareLink, MediaQueries } from '../../libraries/ui';

type LinkProps = {
  href: string;
  children: React.ReactNode;
};

const useStyles = createUseStyles({
  legalLinksRoot: {},
  list: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    paddingTop: 28,
    paddingBottom: 28,
  },
  [MediaQueries.DESKTOP]: {
    legalLinksRoot: {
      maxWidth: 1366,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    list: {
      maxWidth: 784,
    },
  },
});

const Link = ({ href, children }: LinkProps) => (
  <SquareLink target="_blank" rel="noreferrer" noOpener href={href}>
    {children}
  </SquareLink>
);

export const TERMS_OF_SERVICE_LINK =
  'https://helpcenter.hummingbirdtech.com/terms-of-service/';
const PRIVACY_LINK = 'https://helpcenter.hummingbirdtech.com/privacy/';
const LEGAL_CENTER_LINK = 'https://helpcenter.hummingbirdtech.com/legal-center';

function LegalLinks(): React.ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.legalLinksRoot}>
      <ul className={classes.list}>
        <Li variant="small">
          <Link href={TERMS_OF_SERVICE_LINK}>Terms of Service</Link>
        </Li>
        <Li variant="small">
          <Link href={PRIVACY_LINK}>Privacy Policy</Link>
        </Li>
        <Li variant="small">
          <Link href={LEGAL_CENTER_LINK}>Legal Centre</Link>
        </Li>
      </ul>
    </div>
  );
}

export default LegalLinks;
