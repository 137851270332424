import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Drone, Plane, Satellite } from '../icons';
import { MediaQueries } from '../../libraries/ui';

const useStyles = createUseStyles({
  iconListRoot: {
    width: 200,
    position: 'absolute',
    top: 0,
    right: 165,
    minHeight: '100vh',
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 1,
      top: 0,
      right: 86,
      bottom: 0,
      marginLeft: -1,
      borderLeft: '2px dashed green',
    },
  },
  icon: {
    height: 'auto',
    position: 'relative',
  },
  satellite: {
    marginTop: 10,
    width: 240,
  },
  plane: {
    marginTop: 64,
    width: 200,
  },
  drone: {
    marginTop: 58,
    width: 248,
  },
  [MediaQueries.BIG_DESKTOP]: {
    iconListRoot: {
      display: 'flex',
    },
  },
});

type Props = {
  className?: string;
};

function IconList({ className = '' }: Props) {
  const classes = useStyles();

  return (
    <ul className={classNames(classes.iconListRoot, className)}>
      <li>
        <Satellite className={classNames(classes.icon, classes.satellite)} />
      </li>
      <li>
        <Plane className={classNames(classes.icon, classes.plane)} />
      </li>
      <li>
        <Drone className={classNames(classes.icon, classes.drone)} />
      </li>
    </ul>
  );
}

export default IconList;
