import config from '../../config';

const LOGIN = '/login';
const REDIRECT = 'redirect';
const LOCALHOST = 'http://localhost';
const TRUSTED_HOSTS = /^https:\/\/[a-z]+.hummingbirdtech.com/g;

function getRedirectURL(search: URLSearchParams): string {
  let url = search.get(REDIRECT);
  if (url) {
    if (url.includes(LOGIN)) url = url.replace(LOGIN, '');
    if (url.startsWith(LOCALHOST)) return url;
    if (url.match(TRUSTED_HOSTS)) return url;
  }
  return config.appUrl;
}

export default (
  location: any,
  language: string,
  token: string,
  userId: string,
) => {
  const search = new URLSearchParams(location.search);
  const url = getRedirectURL(search);
  const concatenator = url.includes('?') ? '&' : '?';

  window.location.replace(
    `${url}${concatenator}lang=${language}&token=${token}&user=${userId}`,
  );
};
