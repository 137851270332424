import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  a: {
    fill: '#6c6c6c',
    transition: 'fill 0.2s linear',
    '&:hover': {
      fill: '#24b9a9',
    },
  },
});

type Props = {
  className?: string;
};

function ClosedEye({ className = '' }: Props) {
  const classes = useStyles();

  return (
    <svg className={className} viewBox="0 0 31.5 21.234">
      <path
        className={classes.a}
        d="M17.088,9.85a6.725,6.725,0,0,1,6.747,6.694,6.439,6.439,0,0,1-.483,2.45l3.94,3.906a15.828,15.828,0,0,0,4.628-6.356A15.978,15.978,0,0,0,11.7,7.44l2.913,2.893A6.671,6.671,0,0,1,17.088,9.85ZM3.6,6.2,6.679,9.254,7.3,9.87A15.753,15.753,0,0,0,2.25,16.544a16,16,0,0,0,20.744,8.912l.569.563,3.953,3.906,1.715-1.7L5.309,4.5Zm7.455,7.4,2.092,2.072a3.793,3.793,0,0,0-.106.867,4.025,4.025,0,0,0,4.045,4.012,3.805,3.805,0,0,0,.874-.106l2.092,2.072a6.712,6.712,0,0,1-9.713-5.985A6.614,6.614,0,0,1,11.056,13.6Zm5.813-1.04,4.251,4.218.026-.212A4.025,4.025,0,0,0,17.1,12.551Z"
        transform="translate(-2.25 -4.5)"
      />
    </svg>
  );
}

export default ClosedEye;
