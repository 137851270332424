import React, { FormEvent, useContext, useState } from 'react';
import { injectIntl, FormattedMessage, InjectedIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import resetPassword from 'services/api/resetPassword';
import { EmailContext } from 'services/email/EmailProvider';
import { AccountLayout } from 'components';
import { Button, H1, MediaQueries, P } from '../../libraries/ui';
import TextInput, { InputType } from '../../libraries/ui/TextInput';

type Props = {
  intl: InjectedIntl;
};

const useStyles = createUseStyles({
  pageTitle: {
    marginTop: 32,
  },
  form: {
    marginTop: 20,
    overflow: 'hidden',
  },
  input: {
    marginTop: 16,
  },
  button: {
    marginTop: 16,
  },
  [MediaQueries.DESKTOP]: {
    pageTitle: {
      marginTop: 136,
    },
  },
});

function ForgottenPassword({ intl }: Props) {
  const [email, setEmail] = useContext(EmailContext);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  const reset = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resetPassword(email)
      .then(() => setSuccess(true))
      .catch(() => setError(true));
  };

  return (
    <AccountLayout>
      <H1 className={classes.pageTitle} variant="jumbo" theme="bold">
        <FormattedMessage id="forgottenPassword.resetPassword" />
      </H1>
      <form className={classes.form} onSubmit={reset}>
        {error && (
          <P theme="error">
            <FormattedMessage id="forgottenPassword.errorMessage" />
          </P>
        )}
        {success && (
          <P theme="success">
            <FormattedMessage id="forgottenPassword.successHeading" />
            <FormattedMessage id="forgottenPassword.successDescription" />
          </P>
        )}
        <TextInput
          label="Email address"
          className={classes.input}
          name="email"
          type={InputType.EMAIL}
          defaultValue={email}
          onChange={(event: any) => setEmail(event.target.value)}
        />

        <Button
          className={classes.button}
          disabled={!email.length}
          type="submit"
        >
          <FormattedMessage id="forgottenPassword.submitButtonLabel" />
        </Button>
      </form>
    </AccountLayout>
  );
}

export default injectIntl(ForgottenPassword);
