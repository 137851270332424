import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import Element, { ContentTag } from './Element';
import MediaQueries from './MediaQueries';

const useStyles = createUseStyles(() => ({
  root: {
    fontFamily: ["'Roboto'", 'sans-serif'],
    color: '#05051B',
  },
  // variants
  jumbo: {
    fontSize: 24,
  },
  normal: {
    fontSize: 14,
  },
  small: {
    fontSize: 12,
  },
  // themes
  bold: {
    fontWeight: 'bold',
  },
  error: {
    color: '#cc0027',
  },
  success: {
    color: '#4f9053',
  },
  // truncate
  truncate: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  [MediaQueries.DESKTOP]: {
    jumbo: {
      fontSize: 32,
    },
    normal: {
      fontSize: 16,
    },
    small: {
      fontSize: 14,
    },
  },
}));

export type Variant = 'jumbo' | 'normal' | 'small';

export type Theme = 'text' | 'bold' | 'error' | 'success';

type AbstractTextProps = {
  children: React.ReactNode;
  type?: ContentTag;
  variant?: Variant;
  theme?: Theme;
  className?: string;
  htmlFor?: string;
  truncate?: boolean;
};

const Typography = (props: AbstractTextProps) => {
  const classes: any = useStyles();
  const {
    type = ContentTag.P,
    variant = 'normal',
    theme = 'text',
    className = '',
    children,
    htmlFor = null,
    truncate = false,
  } = props;
  return (
    <Element
      className={classNames(
        classes.root,
        { [classes[variant]]: type !== ContentTag.SPAN },
        classes[theme],
        { [classes.truncate]: truncate },
        className,
      )}
      type={type}
      htmlFor={htmlFor}
    >
      {children}
    </Element>
  );
};

export default Typography;
