import type { AppEnvironment } from '.';

export type Config = {
  apiUrl: string;
  appUrl: string;
};

type EnvConfig = Record<AppEnvironment, Config>;

export const defaultConfig = {
  apiUrl: 'http://localhost:8080/api',
  appUrl: 'http://localhost:8000',
};

export const envConfigs: EnvConfig = {
  production: {
    apiUrl: 'https://api.hummingbirdtech.com',
    appUrl: 'https://app.hummingbirdtech.com',
  },
  staging: {
    apiUrl: 'https://api.staging.hummingbirdtech.com',
    appUrl: 'https://app.staging.hummingbirdtech.com',
  },
  internal: {
    apiUrl: 'https://api.int.hummingbird-tech.io',
    appUrl: 'https://app.int.hummingbirdtech.com',
  },
};
