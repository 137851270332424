import React, { useContext } from 'react';
import Dropdown, { Option } from 'react-dropdown';
import { LanguageContext } from 'services/i18n';
import { createUseStyles } from 'react-jss';
import { MediaQueries } from '../../libraries/ui';

const availableLanguages = [
  { value: 'en-UK', label: 'English 🇬🇧' },
  { value: 'pt-BR', label: 'Português 🇧🇷' },
  { value: 'ru-RU', label: 'Русский 🇷🇺' },
  { value: 'uk-UA', label: 'Українська 🇺🇦' },
];

const WHITE = '#fff';
const GREEN_SOLID = '#1fd0bd';
const GREEN_LIGHT = '#f5fbfa';
const BORDER_WIDTH = '2px';

const useStyles = createUseStyles({
  '@global': {
    '.Dropdown-root': {
      // width: 130,
      fontFamily: 'Roboto, Helvetica, sans-serif',
      position: 'relative',
    },
    '.Dropdown-control,.Dropdown-option': {
      fontSize: 14,
      lineHeight: '32px',
      cursor: 'pointer',
      display: 'block',
      paddingLeft: 32,
      backgroundColor: WHITE,
    },
    '.Dropdown-control': {
      position: 'relative',
      background: 'none',
      overflow: 'visible',
      appearance: 'button',
      userSelect: 'none',
      cursor: 'pointer',
      whiteSpace: 'pre',
      outline: 'none',
      backgroundColor: WHITE,
      border: `${BORDER_WIDTH} solid #c3c9c8`,
      borderRadius: '5px',
      '&:hover': {
        borderColor: GREEN_SOLID,
      },
    },
    '.Dropdown-menu': {
      position: 'absolute',
      left: 0,
      right: 0,
      borderRight: `${BORDER_WIDTH} solid ${GREEN_SOLID}`,
      borderBottom: `${BORDER_WIDTH} solid ${GREEN_SOLID}`,
      borderLeft: `${BORDER_WIDTH} solid ${GREEN_SOLID}`,
      borderRadius: [0, 0, 5, 5],
      zIndex: 200,
    },

    '.Dropdown-option': {
      '&:hover': {
        backgroundColor: GREEN_LIGHT,
      },
      '&.is-selected': {
        backgroundColor: GREEN_LIGHT,
      },
    },
    '.Dropdown-root.is-open .Dropdown-control': {
      borderColor: GREEN_SOLID,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottom: 'none',
    },

    '.Dropdown-arrow': {
      borderColor: '#6c6c6c transparent transparent',
      borderStyle: 'solid',
      borderWidth: '5px 5px 0',
      content: ' ',
      display: 'block',
      height: 0,
      position: 'absolute',
      right: 10,
      top: 14,
      width: 0,
    },

    '.is-open .Dropdown-arrow': {
      borderColor: 'transparent transparent #999',
      borderWidth: '0 5px 5px',
    },
    [MediaQueries.DESKTOP]: {
      '.Dropdown-control': {
        borderColor: WHITE,
      },
    },
  },
});

type Props = {
  className: string;
};

function LanguageSwitcher({ className }: Props): React.ReactElement {
  const [language, setLanguage] = useContext(LanguageContext);
  useStyles();

  return (
    <Dropdown
      className={className}
      value={language}
      onChange={(e: Option) => setLanguage(e.value)}
      options={availableLanguages}
    />
  );
}

export default LanguageSwitcher;
